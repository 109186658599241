import classNames from 'classnames'
import { isFunction, values } from 'lodash-es'
import PropTypes from 'prop-types'

export default function CheckboxContent({
  checked,
  children,
  onChange,
  trueValue = true,
  falseValse = false,
  dotClassName,
  className,
  theme = 'primary'
}) {

  function changeHandle(event) {
    isFunction(onChange) && onChange(event.target.checked ? trueValue : falseValse, event)
  }

  return (
    <label className={classNames('inline-flex items-baseline cursor-pointer relative', className)}>
      <span className={classNames(
        'border-primary inline-flex items-center justify-center transition-colors w-16 h-16 rounded-xs flex-shrink-0',
        { 'bg-white border': !checked },
        theme === CheckboxTheme.primary && [{ 'bg-primary': checked }],
        theme === CheckboxTheme.secondary && [{ 'bg-secondary': checked }],
        theme === CheckboxTheme.purple && [{ 'bg-purple': checked }],
        theme === CheckboxTheme.red && [{ 'bg-red': checked }],
        theme === CheckboxTheme.green && [{ 'bg-estimate': checked }],
        theme === CheckboxTheme.gray && [{ 'bg-font-secondary2': checked }],
        theme === CheckboxTheme.orange && [{ 'bg-orange': checked }],
        dotClassName
      )}>
        <i className='border-2 border-white border-l-0 border-t-0 w-6 h-10 origin-center transition-transform' style={{ transform: checked ? 'translateY(-20%) rotate(45deg) scaleY(1)' : 'translateY(-20%) rotate(45deg) scaleY(0)' }}></i>
      </span>
      <input type='checkbox' checked={checked} onChange={changeHandle} className='absolute outline-none m-0 w-0 h-0 -left-[999px]' />
      <span className='text-font text-sm leading-16 ml-6'>{children}</span>
    </label>
  )
}
export const CheckboxTheme = {
  primary: 'primary',
  secondary: 'secondary',
  purple: 'purple',
  red: 'red',
  green: 'green',
  gray: 'gray',
  orange: 'orange',
}
CheckboxContent.propTypes = {
  theme: PropTypes.oneOf(values(CheckboxTheme))
}